import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { tableCustomStyles } from '../../helper/utils';

const API_SERVER_URL = window.CAMPAIN_WWS_URL;
const HANGUP_API_URL = 'https://sip.livepbxphone.us/winet/hangup.php';

const CampaignLiveCalls = ({ user_id }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const pollingRef = useRef();
  const timerRef = useRef();

  const renderDuration = (startTime) => {
    if (!startTime) return "N/A";

    const now = new Date();
    const start = new Date(startTime);

    const durationMs = now - start;
    if (durationMs < 0) return "N/A";

    const durationSeconds = Math.floor(durationMs / 1000);
    const hours = Math.floor(durationSeconds / 3600);
    const minutes = Math.floor((durationSeconds % 3600) / 60);
    const seconds = durationSeconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds.toString().padStart(2, '0')}s`;
    } else {
      return `${seconds}s`;
    }
  };
  
  const fetchLiveCalls = async () => {
    if (!user_id) {
      setError('User ID is required.');
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(`${API_SERVER_URL}/api/live-calls`, { params: { user_id } });
      const allCalls = response.data;

      const filteredCampaigns = allCalls.map(campaign => ({
        ...campaign,
        calls: campaign.calls.filter(call => call.campaign_Id !== "NULL"),
      })).filter(campaign => campaign.calls.length > 0);

      setCampaigns(filteredCampaigns);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching live calls:', err);
      setError('Failed to fetch live calls.');
      setLoading(false);
    }
  };

  const handleHangupCall = async (channel) => {
    try {
      // Validate channel before making the request
      if (!channel) {
        toast.error('Invalid channel. Cannot hang up call.');
        return;
      }

      // Log the exact URL being called
      console.log('Hangup URL:', `${HANGUP_API_URL}?Channel=${encodeURIComponent(channel)}`);

      // Make the request with detailed configuration
      const response = await axios.get(`${HANGUP_API_URL}?Channel=${encodeURIComponent(channel)}`, {
        timeout: 10000, // 10 second timeout
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      // Log the full response for debugging
      console.log('Hangup Response:', response);

      // Check the response body or status
      if (response.status === 200) {
        // Check the response data more comprehensively
        const responseData = response.data;
        console.log('Response Data:', responseData);

        // Modify this condition based on the actual API response
        if (responseData && 
            (responseData.status === 'success' || 
             responseData.message === 'Call hungup' || 
             responseData === 'Call hungup')) {
          toast.success('Call hung up successfully!', {
            position: "top-right",
            autoClose: 3000,
          });
          
          // Refresh live calls after successful hangup
          await fetchLiveCalls();
        } else {
          // If the response doesn't indicate success
          toast.error(`Failed to hang up the call. Response: ${JSON.stringify(responseData)}`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } else {
        // Handle non-200 status codes
        toast.error(`Failed to hang up the call. Status: ${response.status}`, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (err) {
      // Comprehensive error logging
      console.error('Full error object:', err);
      
      // Check if it's an axios error with response
      if (err.response) {
        // The request was made and the server responded with a status code
        console.error('Error response data:', err.response.data);
        console.error('Error response status:', err.response.status);
        
        toast.error(`Hangup Failed: ${err.response.data || 'Unknown error'}`, {
          position: "top-right",
          autoClose: 5000,
        });
      } else if (err.request) {
        // The request was made but no response was received
        console.error('No response received:', err.request);
        toast.error('PYTHON API ERROR. Please check your API.', {
          position: "top-right",
          autoClose: 5000,
        });
      } else {
        // Something happened in setting up the request
        console.error('Error setting up hangup request:', err.message);
        toast.error(`Hangup error: ${err.message}`, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  useEffect(() => {
    fetchLiveCalls(); 

    pollingRef.current = setInterval(() => {
      fetchLiveCalls();
    }, 1000); 

    return () => {
      clearInterval(pollingRef.current);
      clearInterval(timerRef.current);
    };
  }, [user_id]);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, []);

  const columns = [
    { name: "Call ID", selector: row => row.pk_id, sortable: true },
    { name: "From", selector: row => row.call_from, sortable: true },
    { name: "To", selector: row => row.call_to, sortable: true },
    { name: "DID", selector: row => row.did, sortable: true },
    { name: "Direction", selector: row => row.direction, sortable: true },
    {
      name: "Duration",
      cell: row => renderDuration(row.time),
      sortable: false,
    },
    {
      name: "Status",
      selector: row => row.status || "N/A",
      sortable: true,
    },
    {
      name: "Actions",
      cell: row => (
        <button 
          onClick={() => handleHangupCall(row.channel)} 
          className="bg-red-500 text-red px-2 py-1 rounded hover:bg-red-600"
          style={{color:"red"}}
        >
          ✖
        </button>
      ),
      width: '100px',
    },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="live-calls-container">
      <ToastContainer />
      {campaigns.length === 0 ? (
        <p>No live calls with campaign ID at the moment.</p>
      ) : (
        campaigns.map(campaign => (
          <div key={campaign.campaignName} className="campaign-section">
            <h3>{campaign.campaignName} ({campaign.calls.length} {campaign.calls.length === 1 ? 'Call' : 'Calls'})</h3>
            <DataTable
              columns={columns}
              data={campaign.calls}
              pagination
              highlightOnHover
              customStyles={tableCustomStyles}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default CampaignLiveCalls;